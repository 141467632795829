import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import Localize from "../components/localize"
import BackButton from "../components/backButton"
import Seo from "../components/seo"

import { PrivacyPolicySVG } from "../svgs/svg"

const PrivacyPolicy = ({ data: { privacyPolicy }, pageContext }) => {
  const lan = pageContext.locale
  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Privacy Policy" : "Datenschutzerklärung"}
        description={lan === "en" ? "Privacy Policy" : "Datenschutzerklärung"}
        titleTemplate={
          lan === "en"
            ? "Privacy Policy: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Datenschutzerklärung: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div id="content" className="team-button" style={{ paddingTop: 0 }}>
        <div
          className="header  no--margin-menu header-typ2 about--us-header"
          style={{ height: "auto", width: "100%" }}
        >
          <PrivacyPolicySVG />
          <div
            id="headerText"
            className="headerText about--us-text about-text auto-adjust-header"
          >
            <h4>
              {privacyPolicy.title}
              <br />
              {lan === "en" ? "(German)" : ""}
            </h4>
            <p>{privacyPolicy.subDescription}</p>
          </div>
        </div>
        <div className="simpletext">
          <div className="floater"></div>
          <h1>Datenschutzerklärung</h1>
          <BlockContent blocks={privacyPolicy._rawDescription} />
        </div>
        <BackButton
          lan={lan}
          text={lan === "en" ? "Home" : "Startseite"}
          className="back-button"
        />
      </div>
    </>
  )
}

export default Localize(PrivacyPolicy)

export const query = graphql`
  query {
    privacyPolicy: sanityPrivacyPolicy {
      _rawDescription
      subDescription
      title {
        _type
        de
        en
      }
    }
  }
`
